import React from "react"
import services from "../data/services.yml"
import * as styles from "./services.module.scss"

import SEO from "../components/seo"
import News from "../components/news"

const compare = (a, b) => (a < b ? -1 : a > b ? 1 : 0)
const descBy = key => (a, b) => -compare(a[key], b[key])

const Services = () => (
  <>
    <SEO title="SERVICES" description={services.description} />

    <News
      items={services.items
        .sort(descBy("publishedAt"))
        .map(({ name, active, publishedAt, url, description }) => ({
          title: active ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          ) : (
            <div className={styles.name}>
              {name} <div className={styles.inactive}>サービス終了</div>
            </div>
          ),
          date: publishedAt,
          detail: description,
        }))}
    />
  </>
)

export default Services
